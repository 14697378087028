import {title} from 'assets/jss/material-kit-react.js';

const workStyle = {
    section: {
        padding: '30px 0',
    },
    title: {
        ...title,
        textDecoration: 'none',
        textAlign: 'center',
    },
    description: {
        color: '#999',
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    textArea: {
        marginRight: '15px',
        marginLeft: '15px',
    },
    hidden: {
        display: 'none',
    },
    loadingIndicatorContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingIndicator: {
        flex: 1,
    },
};

export default workStyle;
