import React, {useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';
import {FormHelperText, LinearProgress} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

// @material-ui/icons

const useStyles = makeStyles(styles);

export default function WorkSection() {
    return null;
    const classes = useStyles();

    const {register, handleSubmit, watch, errors} = useForm();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const formRef = useRef(null);
    const loadingRef = useRef(null);

    const submitMessage = (data) => {
        setLoading(true);
        fetch('https://us-central1-freespot-io.cloudfunctions.net/contactUs', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.ok) {
                    setMessage('Thank you, your message has been successfully sent.');
                } else {
                    setMessage(
                        'Sorry, there was an error sending your message. Please email us directly at contact@xenreact.com',
                    );
                }
            })
            .catch((error) => {
                setMessage(
                    'Sorry, there was an error sending your message. Please email us directly at contact@xenreact.com',
                );
            });
    };

    const formClasses = classNames({
        [classes.hidden]: loading,
    });
    const loadingIndicatorClasses = classNames({
        [classes.loadingIndicatorContainer]: loading,
        [classes.hidden]: !loading,
    });

    useEffect(() => {
        if (!loading && formRef.current && loadingRef.current) {
            loadingRef.current.style.height = `${formRef.current.offsetHeight}px`;
        }
    }, [loading]);

    console.log(errors);

    return (
        <div className={classes.section}>
            <GridContainer justify="center">
                <GridItem cs={12} sm={12} md={8}>
                    <h2 className={classes.title}>Contact us</h2>
                    <h4 className={classes.description}>
                        Feel free to contact us with your thoughts and suggestions, we will get back to you in a couple
                        of hours. If you would like to join beta tester's group, just let us know!
                    </h4>
                    <div className={loadingIndicatorClasses} ref={loadingRef}>
                        {loading && !message && <LinearProgress className={classes.loadingIndicator}/>}
                        {!!message && <h4 className={classes.description}>{message}</h4>}
                    </div>
                    <form className={formClasses} ref={formRef}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    error={!!errors.name}
                                    labelText="Your Name"
                                    id="name"
                                    formControlProps={{
                                        required: true,
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        name: 'name',
                                        inputRef: register({required: 'Name field is required'}),
                                    }}
                                    formHelperText={
                                        errors.name ? (
                                            <FormHelperText error={true}>{errors.name.message}</FormHelperText>
                                        ) : null
                                    }
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                    error={!!errors.email}
                                    labelText="Your Email"
                                    id="email"
                                    formControlProps={{
                                        required: true,
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        type: 'email',
                                        name: 'email',
                                        inputRef: register({
                                            validate: {
                                                email: (value) => {
                                                    return value.indexOf('@') > -1 || 'Please provide a valid email';
                                                },
                                            },
                                        }),
                                    }}
                                    formHelperText={
                                        errors.email ? (
                                            <FormHelperText error={true}>{errors.email.message}</FormHelperText>
                                        ) : null
                                    }
                                />
                            </GridItem>
                            <CustomInput
                                error={!!errors.message}
                                labelText="Your Message"
                                id="message"
                                name="message"
                                formControlProps={{
                                    required: true,
                                    fullWidth: true,
                                    className: classes.textArea,
                                }}
                                inputProps={{
                                    name: 'message',
                                    multiline: true,
                                    rows: 5,
                                    inputRef: register({
                                        required: 'Message is required',
                                        minLength: {
                                            value: 10,
                                            message: 'Please enter a message at least 20 characters long',
                                        },
                                        maxLength: {
                                            value: 1000,
                                            message: 'Please enter a message no longer than 1000 characters',
                                        },
                                    }),
                                }}
                                formHelperText={
                                    errors.message ? (
                                        <FormHelperText error={true}>{errors.message.message}</FormHelperText>
                                    ) : null
                                }
                            />
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                                    <Button color="primary" onClick={handleSubmit(submitMessage)}>
                                        <SendIcon/> Send
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </GridContainer>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}
