import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/aboutUsPage.js";

import { title } from "assets/jss/material-kit-react.js";
import Parallax from "components/Parallax/Parallax";
import { ContactDetails } from "./LandingPage/Sections/ContactDetails";

const sectionStyle = {
  section: {
    padding: "30px 0",
  },
  title: {
    ...title,
    textDecoration: "none",
    textAlign: "center",
  },
  description: {
    color: "#999",
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
};

const useStyles = makeStyles(styles);
const useSectionStyles = makeStyles(sectionStyle);

export default function PrivacyPolicyPage(props) {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="XenReact"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        filter
        image={require("assets/img/william-iven-gcsNOsPEXfs-unsplash.jpg")}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Privacy Policy</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={sectionClasses.section}>
            <div id="ppBody" className={sectionClasses.description}>
              <div className="innerText">
                This privacy policy has been compiled to better serve those who
                are concerned with how their 'Personally Identifiable
                Information' (PII) is being used online. PII, as described in US
                privacy law and information security, is information that can be
                used on its own or with other information to identify, contact,
                or locate a single person, or to identify an individual in
                context. The policy is also compliant with requirements of
                Regulation (EU) 2016/679 of the European Parliament and of the
                Council of 27 April 2016 (hereinafter referred to as “GDPR”).
                Please read our privacy policy carefully to get a clear
                understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance
                with our website.
                <br />
              </div>
              <span id="infoCo" />
              <br />
              <div className="grayText">
                <strong>
                  What personal information do we collect from the people that
                  visit our blog, website or app?
                </strong>
              </div>
              <br />
              <div className="innerText">
                When ordering or registering on our site, as appropriate, you
                may be asked to enter your name, email address, mailing address,
                phone number or other details to help you with your experience.
              </div>
              <br />
              <div className="grayText">
                <strong>When do we collect information?</strong>
              </div>
              <br />
              <div className="innerText">
                We collect information from you when you register on our site,
                place an order, Open a Support Ticket or enter information on
                our site.
              </div>
              <br /> <span id="infoUs" />
              <br />
              <div className="grayText">
                <strong>How do we use your information? </strong>
              </div>
              <br />
              <div className="innerText">
                {" "}
                We may use the information we collect from you when you
                register, make a purchase, sign up for our newsletter, respond
                to a survey or marketing communication, surf the website, or use
                certain other site features in the following ways:
                <br />
                <br />
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To quickly
                process your transactions.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To send
                periodic emails regarding your order or other products and
                services.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> To follow up
                with them after correspondence (live chat, email or phone
                inquiries)
              </div>
              <span id="infoPro" />
              <br />
              <div className="grayText">
                <strong>How do we protect your information?</strong>
              </div>
              <br />
              <div className="innerText">
                We never ask for credit card numbers.
              </div>
              <div className="innerText">
                We use regular Malware Scanning.
                <br />
                <br />
              </div>
              <div className="innerText">
                Your personal information is contained behind secured networks
                and is only accessible by a limited number of persons who have
                special access rights to such systems, and are required to keep
                the information confidential. In addition, all sensitive/credit
                information you supply is encrypted via Secure Socket Layer
                (SSL) technology.
              </div>
              <br />
              <div className="innerText">
                We implement a variety of security measures when a user places
                an order enters, submits, or accesses their information to
                maintain the safety of your personal information.
              </div>
              <br />
              <div className="innerText">
                All transactions are processed through a gateway provider and
                are not stored or processed on our servers.
              </div>
              <span id="coUs" />
              <br />
              <div className="grayText">
                <strong>Your rights regarding your private information:</strong>
              </div>
              <ul>
                <li>
                  You have right to request deletion of your account and removal
                  of all your information. Please contact us if this is the case
                  and we will handle your request immediately.
                </li>
                <li>
                  You have right request a copy of your private information. The
                  information will be provided in JSON format and will include
                  profile data, tickets, services etc.
                </li>
                <li>
                  If you believe that the information we hold for you is
                  incomplete or inaccurate, you may contact us to ask us to
                  complete or correct that information.
                </li>
              </ul>
              <div className="grayText">
                <strong>Do we use 'cookies'?</strong>
              </div>
              <br />
              <div className="innerText">
                Yes. Cookies are small files that a site or its service provider
                transfers to your computer's hard drive through your Web browser
                (if you allow) that enables the site's or service provider's
                systems to recognize your browser and capture and remember
                certain information. For instance, we use cookies to help us
                remember and process the items in your shopping cart. They are
                also used to help us understand your preferences based on
                previous or current site activity, which enables us to provide
                you with improved services. We also use cookies to help us
                compile aggregate data about site traffic and site interaction
                so that we can offer better site experiences and tools in the
                future.
              </div>
              <div className="innerText">
                <br />
                <strong>We use cookies to:</strong>
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Help remember
                and process the items in the shopping cart.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Understand and
                save user's preferences for future visits.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Compile
                aggregate data about site traffic and site interactions in order
                to offer better site experiences and tools in the future. We may
                also use trusted third-party services that track this
                information on our behalf.
              </div>
              <div className="innerText">
                <br />
                You can choose to have your computer warn you each time a cookie
                is being sent, or you can choose to turn off all cookies. You do
                this through your browser settings. Since browser is a little
                different, look at your browser's Help Menu to learn the correct
                way to modify your cookies.
                <br />
              </div>
              <div className="innerText">
                <br />
                <strong>If users disable cookies in their browser:</strong>
              </div>
              <br />
              <div className="innerText">
                If you turn cookies off, some features will be disabled. Some of
                the features that make your site experience more efficient and
                may not function properly.
              </div>
              <br />
              <div className="innerText">
                However, you will still be able to place orders
                <div className="innerText">
                  Login/View tickets and past invoices
                </div>
                by contacting customer service.
              </div>
              <br />
              <span id="trDi" />
              <br />
              <div className="grayText">
                <strong>Third-party disclosure</strong>
              </div>
              <br />
              <div className="innerText">
                We do not sell, trade, or otherwise transfer to outside parties
                your Personally Identifiable Information unless we provide users
                with advance notice. This does not include website hosting
                partners and other parties who assist us in operating our
                website, conducting our business, or serving our users, so long
                as those parties agree to keep this information confidential. We
                may also release information when it's release is appropriate to
                comply with the law, enforce our site policies, or protect ours
                or others' rights, property or safety. <br />
                <br /> However, non-personally identifiable visitor information
                may be provided to other parties for marketing, advertising, or
                other uses.
              </div>
              <span id="trLi" />
              <br />
              <div className="grayText">
                <strong>Third-party links</strong>
              </div>
              <br />
              <div className="innerText">
                Occasionally, at our discretion, we may include or offer
                third-party products or services on our website. These
                third-party sites have separate and independent privacy
                policies. We therefore have no responsibility or liability for
                the content and activities of these linked sites. Nonetheless,
                we seek to protect the integrity of our site and welcome any
                feedback about these sites.
              </div>
              <span id="gooAd" />
              <br />
              <div className="blueText">
                <strong>Google</strong>
              </div>
              <br />
              <div className="innerText">
                Google's advertising requirements can be summed up by Google's
                Advertising Principles. They are put in place to provide a
                positive experience for users.
                https://support.google.com/adwordspolicy/answer/1316548?hl=en{" "}
                <br />
                <br />
              </div>
              <div className="innerText">
                We have not enabled Google AdSense on our site but we may do so
                in the future.
              </div>
              <span id="calOppa" />
              <br />
              <div className="blueText">
                <strong>California Online Privacy Protection Act</strong>
              </div>
              <br />
              <div className="innerText">
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law's reach stretches well beyond California to
                require any person or company in the United States (and
                conceivably the world) that operates websites collecting
                Personally Identifiable Information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals or
                companies with whom it is being shared. - See more at:
                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                <br />
              </div>
              <div className="innerText">
                <br />
                <strong>
                  According to CalOPPA, we agree to the following:
                </strong>
                <br />
              </div>
              <div className="innerText">
                Users can visit our site anonymously.
              </div>
              <div className="innerText">
                Once this privacy policy is created, we will add a link to it on
                our home page or as a minimum, on the first significant page
                after entering our website.
                <br />
              </div>
              <div className="innerText">
                Our Privacy Policy link includes the word 'Privacy' and can be
                easily be found on the page specified above.
              </div>
              <div className="innerText">
                <br />
                You will be notified of any Privacy Policy changes:
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> On our Privacy
                Policy Page
                <br />
              </div>
              <div className="innerText">
                Can change your personal information:
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> By logging in
                to your account
              </div>
              <div className="innerText">
                <br />
                <strong>How does our site handle Do Not Track signals?</strong>
                <br />
              </div>
              <div className="innerText">
                We don't honor Do Not Track signals and Do Not Track, plant
                cookies, or use advertising when a Do Not Track (DNT) browser
                mechanism is in place. We don't honor them because:
                <br />
              </div>
              <div className="innerText">
                Our website collects information only when you explicitly login
                to it, and we don't collect any information during surfing.
              </div>
              <div className="innerText">
                <br />
                <strong>
                  Does our site allow third-party behavioral tracking?
                </strong>
                <br />
              </div>
              <div className="innerText">
                It's also important to note that we do not allow third-party
                behavioral tracking
              </div>
              <span id="coppAct" />
              <br />
              <div className="blueText">
                <strong>COPPA (Children Online Privacy Protection Act)</strong>
              </div>
              <br />
              <div className="innerText">
                When it comes to the collection of personal information from
                children under the age of 13 years old, the Children's Online
                Privacy Protection Act (COPPA) puts parents in control. The
                Federal Trade Commission, United States' consumer protection
                agency, enforces the COPPA Rule, which spells out what operators
                of websites and online services must do to protect children's
                privacy and safety online.
                <br />
                <br />
              </div>
              <div className="innerText">
                We do not specifically market to children under the age of 13
                years old.
              </div>
              <span id="ftcFip" />
              <br />
              <div className="blueText">
                <strong>Fair Information Practices</strong>
              </div>
              <br />
              <div className="innerText">
                The Fair Information Practices Principles form the backbone of
                privacy law in the United States and the concepts they include
                have played a significant role in the development of data
                protection laws around the globe. Understanding the Fair
                Information Practice Principles and how they should be
                implemented is critical to comply with the various privacy laws
                that protect personal information.
                <br />
                <br />
              </div>
              <div className="innerText">
                <strong>
                  In order to be in line with Fair Information Practices we will
                  take the following responsive action, should a data breach
                  occur:
                </strong>
              </div>
              <div className="innerText">We will notify you via email</div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Within 1
                business day
              </div>
              <div className="innerText">
                <br />
                We also agree to the Individual Redress Principle which requires
                that individuals have the right to legally pursue enforceable
                rights against data collectors and processors who fail to adhere
                to the law. This principle requires not only that individuals
                have enforceable rights against data users, but also that
                individuals have recourse to courts or government agencies to
                investigate and/or prosecute non-compliance by data processors.
              </div>
              <span id="canSpam" />
              <br />
              <div className="blueText">
                <strong>CAN SPAM Act</strong>
              </div>
              <br />
              <div className="innerText">
                The CAN-SPAM Act is a law that sets the rules for commercial
                email, establishes requirements for commercial messages, gives
                recipients the right to have emails stopped from being sent to
                them, and spells out tough penalties for violations.
                <br />
                <br />
              </div>
              <div className="innerText">
                <strong>We collect your email address in order to:</strong>
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send
                information, respond to inquiries, and/or other requests or
                questions
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Process orders
                and to send information and updates pertaining to orders.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Send you
                additional information related to your product and/or service
              </div>
              <div className="innerText">
                <br />
                <strong>
                  To be in accordance with CANSPAM, we agree to the following:
                </strong>
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Not use false
                or misleading subjects or email addresses.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Identify the
                message as an advertisement in some reasonable way.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Include the
                physical address of our business or site headquarters.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Monitor
                third-party email marketing services for compliance, if one is
                used.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Honor
                opt-out/unsubscribe requests quickly.
              </div>
              <div className="innerText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>•</strong> Allow users to
                unsubscribe by using the link at the bottom of each email.
              </div>
              <div className="innerText">
                <strong>
                  <br />
                  If at any time you would like to unsubscribe from receiving
                  future emails, you can email us at
                </strong>
              </div>
              <div className="innerText">
                contact@addonslab.com and we will promptly remove you from{" "}
                <strong>ALL</strong> correspondence.
              </div>
              <br />
              <div className="grayText">
                <strong>Acceptance of this policy </strong>
              </div>
              <div className="innerText">
                Continued use of our site signifies your acceptance of this
                policy. If you do not accept the policy then please do not use
                this site. When registering we will further request your
                explicit acceptance of the privacy policy.
              </div>
              <br />
              <div className="grayText">
                <strong>Changes to this policy</strong>
              </div>
              <div className="innerText">
                We may make changes to this policy at any time. You may be asked
                to review and re-accept the information in this policy if it
                changes in the future.
              </div>
              <br />
              <span id="ourCon" />
              <br />
              <div className="blueText">
                <strong>Contacting Us</strong>
              </div>
              <br />
              <div className="innerText">
                If there are any questions regarding this privacy policy, you
                may contact us using the information below.
              </div>
            </div>
          </div>
          <ContactDetails />
          <div className={sectionClasses.section}>
            <div className={sectionClasses.description}>
              Last Edited on 2020-07-25
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
