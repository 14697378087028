import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/aboutUsPage.js";

import { title } from "assets/jss/material-kit-react.js";
import Parallax from "components/Parallax/Parallax";
import { ContactDetails } from "./LandingPage/Sections/ContactDetails";

const sectionStyle = {
  section: {
    padding: "30px 0",
  },
  title: {
    ...title,
    textDecoration: "none",
    textAlign: "center",
  },
  description: {
    color: "#999",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
};

const useStyles = makeStyles(styles);
const useSectionStyles = makeStyles(sectionStyle);

export default function TermsAndConditionsPage(props) {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="XenReact"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        filter
        image={require("assets/img/william-iven-gcsNOsPEXfs-unsplash.jpg")}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Terms of services</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={sectionClasses.section}>
            <div className={sectionClasses.description}>
              Any software provided by us is furnished under a license and may
              be used and copied only in accordance with the terms of such
              license and with the inclusion of the this copyright notice. The
              software or any copies thereof may not be provided or otherwise
              made available to any other person. No title to and ownership of
              the software is hereby transferred. You may not reverse engineer,
              decompile, defeat license encryption mechanisms, or disassemble
              this software product or software product license. XenReact may
              terminate this license if you don't comply with any of these terms
              and conditions. In such event, licensee agrees to return licensor
              or destroy all copies of software upon termination of the license.
            </div>
          </div>
          <div className={sectionClasses.section}>
            <div className={sectionClasses.description}>
              The above license may be modified for particular software,
              according to customer's requirements. Please contact us for
              details.
            </div>
          </div>
          <ContactDetails />
        </div>
      </div>
      <Footer />
    </div>
  );
}
