import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons
import Camera from '@material-ui/icons/Camera';
import Palette from '@material-ui/icons/Palette';
import Favorite from '@material-ui/icons/Favorite';
// core components
import Header from 'components/Header/Header.js';
import Footer from 'components/Footer/Footer.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import NavPills from 'components/NavPills/NavPills.js';
import Parallax from 'components/Parallax/Parallax.js';

import WorkSection from '../LandingPage/Sections/WorkSection.js';

import styles from 'assets/jss/material-kit-react/views/aboutUsPage.js';

import {title} from 'assets/jss/material-kit-react.js';

const sectionStyle = {
    section: {
        padding: '30px 0',
    },
    title: {
        ...title,
        textDecoration: 'none',
        textAlign: 'center',
    },
    description: {
        color: '#999',
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    textArea: {
        marginRight: '15px',
        marginLeft: '15px',
    },
};

const useStyles = makeStyles(styles);
const useSectionStyles = makeStyles(sectionStyle);

export default function AboutUsPage(props) {
    const classes = useStyles();
    const sectionClasses = useSectionStyles();
    const {...rest} = props;
    const imageClasses = classNames(classes.imgRaised, classes.imgRoundedCircle, classes.imgFluid);
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
        <div>
            <Header
                color="transparent"
                brand="XenReact"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: 'white',
                }}
                {...rest}
            />
            {/* <Parallax small filter image={require('assets/img/snow.jpg')}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>About us</h1>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax> */}
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={sectionClasses.section}>
                    <GridContainer justify="center">
                        <GridItem cs={12} sm={12} md={8}>
                            <h2 className={sectionClasses.title}>Our Mission</h2>
                            <h4 className={sectionClasses.description}>
                                Divide details about your product or agency work into parts. Write a few lines about
                                each one and contact us about any further collaboration. We will respond get back to
                                you in a couple of hours.
                            </h4>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={sectionClasses.section}>
                    <h2 className={sectionClasses.title}>Contact Us</h2>
                    <h4 className={sectionClasses.description}>
                        <div>XenReact.</div>
                        <div>
                            Email: <a href="mailto:contact@xenreact.com">contact@xenreact.com</a>
                        </div>
                    </h4>
                </div>
            </div>
            <Footer />
        </div>
    );
}
