import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(styles);

export default function ProductSection() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Our Mission</h2>
                        <h5 className={classes.description}>
                            We help our customers bringing their vision to reality, creating representative applications for any mobile platform and for web.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Our Services</h2>
                        <h5 className={classes.description}>
                            We are able to assist you with building a mobile or web application from scratch. We also provide technical and continues development supports for existing applications and platforms.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Technologies</h2>
                        <h5 className={classes.description}>
                            We are using React Native for mobile development and React/Vue for web development. Back-end of your choice can be used or integrated with existing database systems (SQL or NoSQL databases, cloud databases).
                            We are also specialized in developing add-ons for XenForo 2.x platform.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    );
}
